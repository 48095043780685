import ClientSuspense from '@components/ClientSuspense'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import usePreferences from '@page-components/CheckoutV3/hooks/usePreferences.main'
import {useEffect} from 'react'
import useLazyAnalyticsV2 from '../hooks/useLazyAnalyticsV2'
import {INTERNAL_EVENTS} from '../types/analyticsServicesTypes'

function TrackOrderPageViewInner() {
  const {preferences} = usePreferences()
  const {website, design} = useWebsiteMenuInformation()
  const analyticsV2 = useLazyAnalyticsV2()

  useEffect(() => {
    if (!preferences?._id) return
    analyticsV2.trackEventAsDefaultOnInternal(INTERNAL_EVENTS.orderPageViewed, {
      menulayoutDesign: website?.menuLayout,
      productCardDesign: design?.design.productCardDesign,
      menuId: preferences?.menuId,
    })
  }, [preferences?._id])

  return null
}

export default function TrackOrderPageView() {
  return (
    <ClientSuspense fallback={null}>
      <TrackOrderPageViewInner />
    </ClientSuspense>
  )
}
