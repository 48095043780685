import gql from 'graphql-tag'

import cartFragment from './cartFragment.main'
import couponFragment from './couponFragment.main'
import deliveryFragment from './deliveryFragment.main'
import paymentMethodFragment from './paymentMethodFragment.main'
import pickupFragment from './pickupFragment.main'
import profileFragment from './profileFragment.main'
import scheduleFragment from './scheduleFragment.main'
import summaryFragment from './summaryFragment.main'

/**
 * Fetches the base information for rendering the Checkout V3.
 * Note: Only add fields that are required for the first render.
 * If you need to fetch info displayed for example when opening a modal, use a separate query.
 */
export default gql`
  fragment checkoutV3Preferences on Preference {
    _id
    websiteId
    storeId
    ...checkoutV3ProfilePreferences
    ...checkoutV3CartPreferences
    ...checkoutV3SchedulePreferences
    ...checkoutV3DeliveryPreferences
    ...checkoutV3PickupPreferences
    ...checkoutV3SummaryPreferences
    ...checkoutV3CouponPreferences
    ...checkoutV3PaymentMethodPreferences
  }

  ${profileFragment}
  ${cartFragment}
  ${scheduleFragment}
  ${deliveryFragment}
  ${pickupFragment}
  ${summaryFragment}
  ${couponFragment}
  ${paymentMethodFragment}
`
