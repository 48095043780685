import {
  WebsiteMenuInformationProvider,
  useWebsiteMenuInformation,
} from '@data/queries/website/websiteMenuInformation.main'
import {getProductIdFromOrderUrl} from '@helpers/middleware/sharedPages/product'
import websiteSSRTranslations from '@helpers/misc/websiteSSRTranslations'
import {parseProductIdFromPath} from '@helpers/router/parseProductIdFromPath('
import useSharedPageRewriteHelper from '@hooks/useSharedPageRewriteHelper'
import AbstractLayout from '@page-components/Layout/AbstractLayout'
import Website from '@page-components/Layout/Website'
import OrderV2 from '@page-components/OrderV2'
import {useMemo} from 'react'

/**
 * Important note:
 * This page is shared between multiple routes (order, category, product and conversational-commerce routes).
 * To enable this magic, read src/helpers/middleware/sharedPages/index.ts
 */
export default function OrderPage() {
  const router = useSharedPageRewriteHelper('productId', getProductIdFromOrderUrl)
  const productId = parseProductIdFromPath(router)
  const {website, design} = useWebsiteMenuInformation()

  const websiteMenuInfo = useMemo(() => ({website, design}), [website._id])

  return (
    <AbstractLayout LayoutComponent={Website}>
      <WebsiteMenuInformationProvider websiteMenuInfo={websiteMenuInfo}>
        <OrderV2 productId={Array.isArray(productId) ? productId[0] : productId} />
      </WebsiteMenuInformationProvider>
    </AbstractLayout>
  )
}

export const getStaticProps = async context => {
  return {
    props: {
      ...(await websiteSSRTranslations(context.params.hostname, ['generic', 'website', 'terms'])),
      lastRevalidatedAt: new Date().toISOString(),
    },
    revalidate: 30 * 60, // 30 minutes
  }
}

export const getStaticPaths = async () => {
  return {
    paths: [],
    fallback: 'blocking',
  }
}
