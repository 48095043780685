import gql from 'graphql-tag'

export default gql`
  fragment checkoutV3PickupPreferences on Preference {
    store {
      _id
      name
      address {
        address
        addressLine2
      }
    }
  }
`
