import gql from 'graphql-tag'

export default gql`
  fragment checkoutV3ProfilePreferences on Preference {
    sessionEmail
    sessionPhone
    sessionEmailHasAccount
    user {
      _id
      name
      email
      phone
      profile {
        firstName
        lastName
      }
    }
  }
`
