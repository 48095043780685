import gql from 'graphql-tag'

export default gql`
  fragment checkoutV3CartPreferences on Preference {
    menuId
    cart {
      websiteCoinsToSpend
      justoCoinsToSpend
      items {
        _id
        amount
        isOutOfStock
        priceWithoutDiscounts: unitPrice(includeDiscount: false)
        priceWithDiscounts: unitPrice(includeDiscount: true)
        descriptionItems {
          title
          selections
        }
        outOfStockMessage
        product {
          _id
          name
          image {
            _id
            url: resizedURL(width: 300, height: 300, format: "webp")
          }
        }
      }
    }
  }
`
