import gql from 'graphql-tag'

export default gql`
  fragment checkoutV3CouponPreferences on Preference {
    websiteId
    couponCode
    cart {
      couponCode
      couponStatus {
        errorMessage
        couponId
        name
        discount
      }
    }
  }
`
