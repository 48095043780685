import gql from 'graphql-tag'

export default gql`
  fragment checkoutV3DeliveryPreferences on Preference {
    placeId
    tableName
    directions {
      duration
    }
    address {
      _id
      address
      addressSecondary
      addressLine2
      acceptsNoLine2
      location
      comment
    }
  }
`
