import gql from 'graphql-tag'

export default gql`
  fragment checkoutV3PaymentMethodPreferences on Preference {
    _id
    paymentType
    cardId
    otherPaymentType
    website {
      _id
      stores {
        _id
      }
    }
    card {
      _id
      brandId
      brandName
      last4
      cardType
    }
    websiteCoinsToSpend
    justoCoinsToSpend
  }
`
