import {GetCheckoutV3Query, GetCheckoutV3QueryVariables} from '@data/__generated__/types.main'
import checkoutV3Preferences from '@data/fragments/checkout/v3'
import isServerSide from '@helpers/misc/isServerSide'
import useWebsiteId from '@hooks/useWebsiteId'
import {useApolloQuery as useQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

export default function usePreferences() {
  const websiteId = useWebsiteId()

  const result = useQuery<GetCheckoutV3Query, GetCheckoutV3QueryVariables>({
    query: gql`
      query getCheckoutV3($websiteId: ID) {
        preferences: userPreferences(websiteId: $websiteId) {
          ...checkoutV3Preferences
        }
      }
      ${checkoutV3Preferences}
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first',
    pollInterval: 1000 * 60 * 10,
    omit: isServerSide()
  })

  return {
    preferences: result.preferences,
    loading: result.loading
  }
}
