import gql from 'graphql-tag'

export default gql`
  fragment checkoutV3SummaryPreferences on Preference {
    tipAmount
    tipPercentage

    deliveryType

    address {
      _id
    }

    store {
      _id
      hideTip
    }

    website {
      _id
      noTipByDefault
      showZeroTipOption
      bagCost
      loginWithExternalProvidersAvailable
    }

    loyaltyProgram

    cart {
      pointsDetails {
        amount
        name
      }
      deliveryFee
      serviceFee
      itemsPrice
      subtotalWithProductDiscount: itemsPrice(
        includeDiscount: true
        includeCoupon: false
        includeJustoCoins: false
        includeLoyaltyDiscount: false
        includeWebsiteCoins: false
      )

      priceBeforeWebsiteCoins: itemsPrice(
        includeDiscount: true
        includeCoupon: true
        includeJustoCoins: false
        includeWebsiteCoins: false
      )
      amountToPay
      totalPrice
      realPrice: itemsPrice(includeDiscount: false)
      acceptsTip
      hasFixedAmountTips
      tipOptions
      tipPercentagesOptions
      calculatedTipAmount

      couponStatus {
        code
        name
        discount
      }

      specialRequirementsIds

      loyaltyTransactionStatus {
        transactionId
        loyaltyName
        discount
      }
    }
  }
`
